import "./src/styles/global.css"
import "./src/styles/styles.css"
//import './src/polyfills/promise-resolvers.js';

import AOS from 'aos';
import 'aos/dist/aos.css';

export const onClientEntry = () => {
  
 
AOS.init();
};